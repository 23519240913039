.my_add_event_form_field {
    display: flex;
    gap: 4px;
  }
  .date_picker_eventform {
    display: flex;
    flex-direction: column;
  }
  .my_rangePicker {
    height: 40px !important;
    width: 100% !important;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb;
    width: 100% !important;
  }
  .DateInput_input {
    padding: 5px 9px 5px !important;
    font-size: 16px !important;
    width: 100%;
    font-weight: 400 !important;
  }
  /* // Add Event List page */
  .date_picker_Add_event_list{
    display: flex;
    flex-direction: column;
  }
  ._date_picker_{
    height: 40px;
  }
  .banner_list_tale_heading{
    margin-bottom: 20px !important;
  }